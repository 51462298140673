export default {
    "nav.home":"ホーム,",
    "nav.businessField":"事業内容",
    "nav.relatedDocuments":"関連資料",
    "nav.NewsCenter":"ニュースセンター",
    "nav.aboutUs":"企業情報",
    "nav.paper":"白書",
    "new.plachodel":"データなし",
    "banner.ProjectDescription":"プロジェクト紹介",
    "banner.txt1-1":"WFCA ―バーチャルとリアルをつなぎ、新たなデジタル経済を創造する",
    "banner.txt1-2":"WFCAは、現在日本が直面している社会的・経済的課題を解決し、日本のソフトパワーとハードパワーを融合させ、日本の産業がデジタル時代に参入できるよう、バーチャルとリアルを超越したデジタルエコノミーの構築に取り組んでいます。",
    "banner.txt1-3":"WFCAは、日本のアニメ文化と伝統的な職人精神を深く融合させたメタ・ユニバース・プラットフォームを構築していきます。 アニメのNFT市場を開拓し、NFTを通じてクリエイターのデジタル化を支援するほか、日本各地の高品質な商品を販売するバーチャルショッピングモールの開設、オフラインの実店舗のオープンによりO2Oクローズドループの形成を推進してまいります。",
    "banner.txt1-4":"一方、WFCAは、暗号資産に対応するだけでなく、完全な法定通貨口座サービスを提供するブロックチェーン銀行になるための申請も進めています。 また、取引所、資産保管プラットフォーム、資産管理端末などの金融インフラも構築します。",
    "banner.txt1-5":"WFCAの目標は、仮想世界と現実世界のつなぐ架け橋となり、新たなデジタル経済を創造することです。 私たちは、この新しいデジタル・エコシステムに参加するクリエイターやプロダクト・プロデューサーを歓迎します。 一緒にWeb3の新時代に足を踏み入れましょう！",
    "banner.ProjectPurpose":"プロジェクトの目的",
    "banner.txt2-1":"WFCAプロジェクトは、ブロックチェーン、デジタルアセット、メタバースなどの最先端技術をベースに、仮想現実と統合されたデジタル世界の構築に取り組んでいます。 ここでは、日本の伝統文化、産業、芸術をデジタル化し、メタバース、NFTなどの形で革新的に発信することができる。また、ユーザーがデジタル空間での体験を楽しむだけでなく、物理的な商品やサービスにも手に入れる、バーチャルとリアルをつなぐビジネスモデルを構築していきます。 WFCAは、デジタルのエンパワーメントを通じて、日本経済が新たな成長を遂げ、デジタルプラットフォームを通じて世界文化の交流を促進することを望んでいます。想像力と革新に満ちたデジタルの未来に期待しましょう。",
    "banner.txt3-1":"WFCAは、日本の豊かな文化が集い、世界中のクリエイターとユーザーを結び付け、安全でオープンな仮想空間の作成を目指しています。 WFCAは、ユーザーが仕事、学習、娯楽することができる没入型の仮想コンテンツコミュニティであり、革新的なモデルを通じて伝統産業に力を与え、経済成長の勢いを拡大する現実志向のデジタルエンパワーメントプラットフォームでもあります。WFCAは、バーチャルとリアルが融合したこのデジタルワールドを構築し、ブロックチェーンとデジタル技術がもたらす無限の可能性を共有するために、私たちと協力してくれるより多くのパートナーの参加を歓迎します。",
    "banner.DevelopmentVision":"WFCAビジョン",
    "home.numSpice":"デジタル資産",
    "home.numTitle":"革命は将来トークン化されます。",
    "home.numCenter":"WFCAのビジョンは、ブロックチェーン主導のデジタル経済サークルを構築し、仮想資産と物理資産を結び付け、デジタル世界と物理世界の深い統合を実現することです。",
    "NextGeneration":"次世代の暗号資産取引所",
    "NextGeneration.txt1":"ブロックチェーンはWFCAプロジェクトの技術的基盤であり、ブロックチェーン技術を用いて仮想資産の真正性と証明可能性を保証する信頼メカニズムを構築しています。",
    "NextGeneration.txt2":"WFCAは、NFTテクノロジーを利用して仮想資産に独自性を与え、仮想資産を物理資産に固定し、仮想資産の希少性を実現することを目指しています。",
    "NextGeneration.txt3":"WFCAはメタバース技術を深く応用し、仮想空間と現実空間を繋ぎ、仮想世界と現実世界の接続を実現していきます。",
    "NextGeneration.title1":" ブロックチェーン",
    "NextGeneration.title3":"メタバース",
    "businessField.BusinessOverview":"事業の概要",
    "businessField.title1":"5Gビジネス",
    "businessField.title2":"カーボンニュートラル",
    "businessField.title3":"店舗開発",
    "businessField.title4":"新エネルギー車",
    "businessField.title5":"メタバースアプリ",
    "businessField.title6":"アニメIP",
    "businessField.title7":"仮想資産",
    "Business.txt1-1":"WFCAは、効率的で正確かつ持続可能なスマート農業の実現を目標に、農業分野における5G技術の応用について詳細な研究を行っています。",
    "Business.model1-1":"5Gは次世代通信システムとして注目を集めている。 ",
    "Business.model1-2":"5Gを活用した産業が隠れており、農業も例外ではない。",
    "Business.model1-3":"様々な分野も5Gによって大きく進化することが期待されている。",
    "Business.model1-4":"5G農業アプリケーション",
    "Business.model1-5":"〇 自動化、無人農業機械の超省力化",
    "Business.model1-6":"遠隔監視の下で、農業機械の種類を増やし、現場での無人作業を実現します。",
    "Business.model1-7":"〇 データの精度",
    "Business.model1-8":"農業機械や無人機などから収集された成長データや畑の環境情報、情報、収穫量、市場データなどを分析し、作付け計画を最適化したり、データに基づく農場管理の効率を向上させることで、無駄なく、利益を上げつつ、持続可能な農業を実現します。必要な作物の栽培と収穫のみを許可します。",
    "Business.model1-9":"5G技術を農業に適用する利点",
    "Business.model1-10":"〇 生産力向上による収益をもたらす農業の利点",
    "Business.model1-11":"‧ 規定された期間内に規定数の農作物を生産する。",
    "Business.model1-12":"‧ 単位面積あたりの収量と味わいを向上させる。",
    "Business.model1-13":"‧ 一人で広範なエリアをカバーすることができる。",
    "Business.model1-14":"‧ データに基づく無駄のない施肥を行い、化学肥料や農薬の使用を減少させる。",
    "Business.model1-15":"〇 軽労作業、省力化",
    "Business.model1-16":"‧ 農業機械などの自動化によって重労働を除去し、働き方改革を実現する。",
    "Business.model1-17":"‧ 自動運転農業機械により取り扱える作業種類が増え、作業量を減少させる。",
    "Business.model1-18":"〇 環境負荷の低減",
    "Business.model1-19":"‧ データに基づく農業によって肥料、農薬、水の使用を最適化することは、有機農業に適している。",
    "Business.model1-20":"〇 農業の多様な機能を維持",
    "Business.model1-21":"‧ 規模拡大を促進し、廃棄された農地の活用が可能となる。",
    "Business.model1-22":"‧ 気候変動に対抗する持続可能な農業を実現する。",
    "Business.model1-23":"01",
    "Business.model1-24":"02",
    
    "Business.txt1-2":"WFCAは、バーチャルの世界と現実の世界をつなぐ新たなショッピング・プラットフォームを目指し、日本と中国で小売店「九九華聯」の企画・建設を進めています。",
    "Business.model2-1":"プロジェクト概要",
    "Business.model2-2":"創造日常生活と所属感",
    "Business.model2-3":"‧ 様々なコンテンツを一か所に集約できるショップ。",
    "Business.model2-4":"‧ 年齢や性別に関係なく、多くの人が気軽に訪れることができるスペース。",
    "Business.model2-5":"‧ 豪華な装飾はなく、緑に囲まれた明るいオープンスペースで心身をリラックスできる場所。",
    "Business.model2-6":"‧ 何時間も過ごしても窮屈さを感じない、あらゆることがスマートフォンでできる場所。",
    "Business.model2-7":"‧ それぞれの人に心地よい時間を過ごす場所を提供。",
    "Business.model2-8":"‧ 車からでも客の楽しみが伺える。",
    "Business.model2-9":"‧ 外には木のデッキやテラスがある。",
    "Business.model2-10":"‧ 芝生の上に座ることができるスペースがある。",
    "Business.model2-11":"日本全国があなたの手の中に",
    "Business.model2-12":"‧ スマートフォンを日本の名所や史跡に置くと、各地域の特産品が表示されます。欲しいものを簡単に閲覧して購入できます。",
    "Business.model2-13":"‧ 一つの場所で日本全国を旅することができます。",
    "Business.model2-14":"‧ 日本全国の商品を取り扱う新世代のコンビニエンスストア。",
    "Business.model2-15":"‧ 買い物だけでなく、コミュニティスペースとしても利用できます。",
    "Business.model2-16":"‧ 若者たちが集まり、くつろぎながら日本全国に関する情報を交換する場所。旅行気分を味わえます。",
    "Business.model2-17":"日本全国があなたの目の前に",
    "Business.model2-18":"‧ VRメガネを使用して壁に掲げられた日本地図に指を置くと、位置が表示されます。",
    "Business.model2-19":"‧ メタバースの空間でショッピングができます。",
    "Business.model2-20":"‧ 日本全国の商品を簡単に購入できる新世代のコンビニエンスストア。",            
    "Business.model2-21":"‧ 特定の場所を見ることができる事実に基づいてオンライン会議を行い、遠くに行けない日本の地域を訪れることができます。自分が見たい景色をコンビニエンスストアのように楽しむことができます。",
    "Business.model2-22":"ビジネスモデル",
    "Business.model2-23":"‧ ブロックチェーンとNFTを活用した新しいビジネスモデル。",
    "Business.model2-24":"「DDE FINTECH HOLDING」の独自のブロックチェーン技術を使用したデジタルペイメントを通じて、ショッピング支払いや経済分配を促進します。",
    "Business.model2-25":"さらに、NFTプラットフォーム「DMN」を活用してコンテンツの制作、配信、企画をサポートします。",
    "Business.model2-26":"‧ 中国華聯との提携。",
    "Business.model2-27":"品質の高い商品を日本内で提供するための流通基盤を整備し、価値ある日本の商品を中国に輸出支援します。また、入境ビジネスを通じて中国観光客の誘致と消費促進を行います。",
    "Business.model2-28":"‧ 全国の99華聯とコミュニティとの提携。",
    "Business.model2-29":"全国の99華聯とWFCクラブとの提携により、各都道府県の特産品やサービスを全国の店舗とメンバーに共有・配布する支援を行います。",

    "Business.txt1-3":"WFCAは、ユーザーがアバターを操作し、他のユーザーとコミュニケーションやインタラクションができるユニークなデジタルメタバースを構築しています。",
    "Business.model3-1":"Metaverse（メタバース）は、「Meta」（超越）と「Universe」（世界）の組み合わせです。これは造語であり、Metaverse（メタバース）と呼ばれます。それはインターネット上の仮想空間であり、ユーザーは自分自身の別の自己（「アバター」と呼ばれる）を操作することができます。これは人々が行動し、コミュニケーションを取るための空間です。ここでは、様々なサービスやコンテンツが提供される仮想コミュニティとなっています。仮想宇宙内では、誇るべきアニメーションや実際の製品のゲームや店舗などのコンテンツを提供し、経済圏を拡大して特色ある経済圏を築くことが考えられています。",
    "Business.model3-2":"アニライブ",
    "Business.model3-3":"ライブミュージック x メタバース",
    "Business.model3-4":"‧ NFTチケットを使用してライブパフォーマンスに参加",
    "Business.model3-5":"‧ ライブ終了後、記念としてNFTを所有できる（メモリアルNFT）",
    "Business.model3-6":"‧ NFTホルダーへの特典",

    "Business.txt1-4":"WFCAは、BTCやETHといった主流の暗号通貨をサポートし、必要に応じてより多くのパブリック・チェーンに徐々に対応していきます。",
    "Business.model4-1":"アフリカ、シエラレオネのダイヤモンド鉱山採掘権の取得。3万円相当のダイヤモンドをブロックチェーンに結びつけます。",                   
    "Business.model4-2":"アダマントコインは1:1の比率でWFCAと交換可能です。",
    "Business.model4-3":"シエラレオネの鉱山から採掘されます。",
    "Business.model4-4":"3万円を基準としたブロックチェーンで、ADTDと相互に交換可能です。",

    "Business.txt1-5":"WFCAは、カーボンニュートラルと炭素資産管理のため、リード・パークで炭素を吸収する森林を育ています。",
    "Business.model5-1":"「吉武」とは",
    "Business.model5-2":"大量の二酸化炭素を吸収し、効果的に地球温暖化を抑制する材料",
    "Business.model5-3":"ススキ",
    "Business.model5-4":"熱帯雨林",
    "Business.model5-5":"稲わら",
    "Business.model5-6":"トウモロコシの茎",
    "Business.model5-7":"ネット重量（1亩あたりの重量、トン）",
    "Business.model5-8":"二酸化炭素吸収量（1亩あたりの量、トン）",
    "Business.model5-9":"酸素出力量（1亩あたりの量、トン）",
    "Business.model5-10":"注：農業廃棄物および残渣の生産量は低く、密度も低く、輸出も少ないです。高い輸送コストのため、これは生物質エネルギーや植物エネルギーとは呼べません。",
    "Business.model5-11":"炭素クレジット（Rochiku）",
    "Business.model5-12":"ススキ産業のビッグデータを構築し、大規模データシステムを通じて、栽培者、管理者、加工者、購買業者がサービスを提供します。また、産業全体のデータサービスも提供します。",
    "Business.model5-18":"製品のトレンド",

    "Business.txt1-6":"WFCA は、UAE初の電気自動車メーカーであるArk Motorsと協力して、環境に優しい電気自動車の製造に取り組んでいます。",
    "Business.model6-1":"方舟自動車",
    "Business.model6-2":"私たちは、アラブ首長国連邦アブダビにおける初の電動車製造メーカーとして、環境に優しい電動車の製造と販売に取り組みます。99％の製品は中国および他の世界地域に販売拠点を拡大する予定です。",
     "Business.model6-3":"V2H（車両から家庭へ）",
     "Business.model6-4":"V2H（車両から家庭へ）は、電動車（EV）またはプラグインハイブリッド車（PHV）が家庭内で電力を利用できるシステムです。99朵花（Rengo）は、このV2HシステムをEV車両とバンドルして販売し、環境保護への貢献を目指します。",

    "Business.txt1-7":" WFCAの「Animeta」は、アニメを中心としたNFT取引プラットフォームです。",
    "Business.model7-1":"分散型NFT取引プラットフォーム「ANIMETA」は、関連するIP、作品、周辺商品、NFT、アニメNFTを結びつけ、オープンなエコシステムを構築し、トークンの創造と取引を行うためにアニメ業界に適用されます。これは1つの事柄です。ANIMETAは、後疫病時代において、アニメ業界の物理的な制約が産業の発展のボトルネックとなっています。そのため、ANIMETAプラットフォームはNFT技術と無料取引体験を活用し、各アニメ作品をNFTに変換することを目指しています。色彩学を導入することで、各アニメ作品はNFTを通じて関連付けられ、資産として、ドメイン名として、プラットフォームの派生品として取引可能となります。これはプラットフォーム外の資産の相互運用性を実現することを目指しています。",
    "Business.model7-2":"プラットフォームの展示です。",
    "aboutUs.Introduction":"役員紹介",
    "aboutUs.Team":"メンバー紹介",
    "ababoutUs.Partner":"パートナー",
    "about.name-1":"代表取締役社長",
    "about.txt1-1":"東京石舍株式会社 CEO（1980年～1987年）",
    "about.txt1-2":'代表作：東京ディズニーランド「イッツ・ア・スモールワールド」',
    "about.txt1-3":"Status Land株式会社（1988年～1992年） 代表取締役/社長",
    "about.txt1-4":"株式会社NHK情報ネットワークと有料FAXサービス",
    "about.txt1-5":"Spike株式会社 (1993年～2001年)取締役副社長",
    "about.txt1-6":'ゲーム制作（プレイステーション）：『ルパン三世 クロニクル』',
    "about.txt1-7":'映像制作：鈴木清順監督『ガンナー・オペラ』ヴェネチア国際映画祭審査員特別賞',
    "about.txt1-8":"Dream Eggs株式会社（2001年～2017年）代表取締役/社長",
    "about.txt1-9":'携帯電話向けコンテンツ企画制作 "カタログ事業"',
    "about.txt1-10":"ヘリオス（当時日本最大のEC動画配信事業）との協業",
    "about.txt1-11":"レオパレスへのコンテンツ提供",
    "about.txt1-12":'テレビ東京「カッパ巻き」アニメ132話制作',
    "about.txt1-13":"38枚のDVDを制作",
    "about.txt1-14":'「ビートルズ・クロニクル」「ロナウジーニョの世界」の出版・制作',
    "about.txt1-15":"99華聯株式会社（現） 代表取締役社長",

    "about.name-2":"最高財務責任者",
    "about.txt2-1":"東京一椏大学商学部卒業 (1997)",
    "about.txt2-2":"野村證券株式会社　社長。（1997-1998）",
    "about.txt2-3":"野村證券ニューヨーク本部長。",
    "about.txt2-4":"東京本部長。",
    "about.txt2-5":"東京本社国際金融部長",
    "about.txt2-6":"バンコク駐在員事務所CEO",
    "about.txt2-7":"野村ハイフォン工業団地",
    "about.txt2-8":"SSD Japan 株式会社（1998年 - 2015年）",
    "about.txt2-9":"(Ultimelit株式会社に社名変更）CEO（1998年～2015年）",
    "about.txt2-10":"Evertech 株式会社CEO（2010年 - 2015年）",
    "about.txt2-11":"ネクストコイン株式会社 CEO（2018年 - 現在）",

    "about.name-3":"最高マーケティング責任者",
    "about.txt3-1":"日本大学経済学部卒業（1989年）",
    "about.txt3-2":"イデア総合研究所 代表取締役社長（1989年～2002年）",
    "about.txt3-3":"東京第一株式会社CEO（2002年～2015年）",
    "about.txt3-4":"IDEA株式会社（2015年～2018年）CEO",
    "about.txt3-5":"Next Coin株式会社 システム事業部長（2018年～現在）",
    "about.txt3-6":"一般社団法人日本暗号資産ビジネス協会（現）NFT事業部",

    "about.name-4-1":"シニアITエンジニア",
    "about.name-4-2":"コンピュータサイエンス博士",
    "about.txt4-1":"人工知能とモノのインターネットの分野で豊富な経験を持っています。",
    "about.txt4-2":"研究者及びエンジニアとして、リム博士は最近、コンセンサスや暗号化といったブロックチェーンの低レベルの課題に焦点を当てて、ブロックチェーン技術に取り組み始めました。",
    
    "about.name-5-1":"シニア・ソフトウェア・エンジニア",
    "about.txt5-1":"フリーランスのウェブ開発者として長年働いた後、暗号通貨に興味を持ち、2年前にdAppの開発を始めました。",

    "about.name-6-1":"ソフトウェア エンジニア",
    "about.txt6-1":"新卒で大学時代にブロックチェーンテクノロジーのトラストレス・アーキテクチャに強い​​関心を持ち、ほとんどの時間をブロックチェーンの研究に費やし、現在は dApp 開発チームの一員です。",

    "about.name-7-1":"ソフトウェア エンジニア",
    "about.txt7-1":"Solidity 開発で 2 年以上の経験を持っています。 Syhami は、CPAC 2020 に参加した BcVote プロジェクトの中心メンバーであり、 バックエンド技術の専門家です。",

    "see.more":"もっと見る",
    "current.location":"現在位置",
    "Partner.Details":"パートナー",

    "Partner.title1-1":"「アーク電気自動車 - アラブ首長国連邦の環境に優しい選択：",
    "Partner.txt1-2":"あらすじ",
    "Partner.txt1-3":"アーク・エレクトリック・ビークルは、UAEのアブダビで発売された電気自動車の新ブランドで、環境に優しい性能と豪華な装備で注目を集めています。 アラブ首長国連邦初の電気自動車メーカーであるアークは、未来の都市でエコで高品質な移動体験を乗客に提供することをお約束します。",
    "Partner.txt1-4":"特徴：",
    "Partner.txt1-5":"VIP - タクシー：「グリーン」タクシーとして知られるアークEVは、オール電化、排気ガスフリー、ヘイズフリーで環境に優しい。",
    "Partner.txt1-6":"近隣住民に優しい：21世紀の都市のニーズに適応し、低騒音、無公害で、都市住民に静かな移動環境を提供します。",
    "Partner.txt1-7":"究極の快適性：電動ドアによる安全性の向上、全席からパノラマビューが楽しめるVIPなインテリア。",
    "Partner.txt1-8":"インテリアの可変性：タクシー、ビジネスクラス、VIP席への転換が可能で、さまざまな旅行ニーズに対応します。",
    "Partner.txt1-9":"革新的なデザイン：100％カーボンコンポジット、100％電動パワー、カーブしたパノラマウィンドスクリーン、プレミアムなオープンスペース。",
    "Partner.txt1-10":"仕様：",
    "Partner.txt1-11":"カテゴリー: Farak (ARK)",
    "Partner.txt1-12":"サイズ: L x W x H 4.65m x 1.90m x ?",
    "Partner.txt1-13":"空車重量：1600kg",
    "Partner.txt1-14":"積載量：500kg",
    "Partner.txt1-15":"航続距離：最大400km",
    "Partner.txt1-16":"最高速度：160km/h（制限あり）",
    "Partner.txt1-17":"排出ガス：真のゼロ・エArk EV – UAEの環境に優しい選択",
    "Partner.txt1-18":"バッテリー容量：63kWh",
    "Partner.txt1-19":"創設者:",
    "Partner.txt1-20":"シェイク・モハメッド・ビン・アハメド・ビン・ハムダン・アル・ナヒヤーン殿下はUAEの指導者、アーク電気自動車の創業者、大統領の甥です。 彼のビジョンは、UAEに電気自動車を導入し、都市にクリーンで持続可能な交通手段を提供することです。",
    "Partner.txt1-21":"マイルストーン：",
    "Partner.txt1-22":"2022年11月1日～2日：Ark EVはアラブ首長国連邦で盛大な発表イベントを開催し、新しい電気モデルを展示し、メディアや政府関係者を含むあらゆる階層の人々の注目を集めた。",
    "Partner.txt1-23":"連絡先：",
    "Partner.txt1-24":"電子メール: info@arkmotors.tech",
    "Partner.txt1-25":"住所: 15 階、ミレニアム プラザ ホテル、ドバイ、アラブ首長国連邦",
    "Partner.txt1-26":"Ark Electric Vehiclesは中東初の電気自動車メーカーとして、環境に優しいモビリティの新しい体験をお届けします。 ご期待ください！(著作権 © ARK MOTORS & TECHNOLOGY L.L.C.)",


    "Partner.title2-1":"DDE",
    "Partner.txt2-1":"パーパス:",
     "Partner.txt2-2":"テクノロジーの力で人と企業を結び、社会の可能性を広げる。",
     "Partner.txt2-3":"時代の変化や未曾有の出来事、技術の進歩により、社会や産業の状況は大きく変わろうとしています。今や、企業の価値は単に業績だけでは測れなくなりました。",
     "Partner.txt2-4":"これまでのように自社だけが勝ち、経済的な価値だけを追求することでは、持続的で愛される存在には決してなれないと考えています。",
     "Partner.txt2-5":"クライアントへの価値、パートナーへの価値、社会や環境への価値。これらすべてを総合的に高めていくこと。短期的な数字に満足するのではなく、人々を育て、事業を築き、持続可能な未来を創造すること。",
     "Partner.txt2-6":"それが、私たちDDEのコミットメントです。。",
     "Partner.txt2-7":"トップメッセージ:",
     "Partner.txt2-8":"私たちDDEのヴィジョンは、情報に価値を見出し、それをビジネス革新の源泉として捉え、社会への貢献を通じて、新しい未来を創り出すことです。常に最新技術やアイディアを柔軟に取り入れ、先進的なアプローチでビジネスを展開し、次世代に向けたイノベーションを積極的に推進しています。",
     "Partner.txt2-9":"私たちの活動は、社会全体の未来にポジティブな影響を与えることができると強く信じています。変化の激しい現代社会だからこそ、恐れずに挑戦し、常に新しい可能性を追求し、行政や大手企業ができないことを実現していく。そして、これまで誰もできなかったようなインパクトのある社会貢献を果たすことが私たちの使命だと考えています。",
     "Partner.txt2-10":"チャレンジすることは、私たちのDNAに刻まれています。様々な活動を通じて常に新しい社会貢献のあり方を示していくリーディングカンパニー（変革者）でありたいと思っています。",
     "Partner.txt2-11":"短期的な成功に満足することなく、絶えず成長し続けるために改善を重ね、より良い未来の実現に向けて進化し続ける。長期的な視点で事業を展望し、いかに持続的な未来を創出することができるかを考える。",
     "Partner.txt2-12":"私たちDDEは、これからも革新と社会貢献を結び、持続的な未来を築くテクノロジー分野のプロフェッショナル集団として進化し続けます。",
     "Partner.txt2-13":"会社概要",
     "Partner.txt2-14":"DDEフィンテックホールディングス株式会社",
     "Partner.txt2-15":"英語名称",
     "Partner.txt2-16":"DDEフィンテックホールディングス株式会社",
     "Partner.txt2-17":"設立",
     "Partner.txt2-18":"2003 年 10 月 1 日",
     "Partner.txt2-19":"代表取締役社長",
     "Partner.txt2-20":"代表取締役 奥島 健司",
     "Partner.txt2-21":"資本金",
     "Partner.txt2-22":"5,000万円（2019年12月末時点）",
     "Partner.txt2-23":"社員数",
     "Partner.txt2-24":"50名（2020年4月末時点）",
     "Partner.txt2-25":"本社所在地",
     "Partner.txt2-26":"〒135-0091 東京都港区お台場2-2-4",
     "Partner.txt2-27":"会社名",

     "Partner.title3-1":"Libes ー　eスポーツに新たな経済圏を作る",
     "Partner.txt3-1":"概要：",
     "Partner.txt3-2":"Libesは、eスポーツ市場に新たな経済エコシステムを創出することにより、eスポーツの経済性と価値を高めることを目的としたプラットフォームです。伝統的なeスポーツの経済モデルに加え、サインアップ、プレゼント、NFTなどの活動を通じて、P2P要素やブロックチェーンをプラットフォームに統合し、経済活動の幅を広げ、eスポーツプレイヤーに経済的サポートを提供し、ユーザーに全く新しいレベルの楽しみをもたらします。",
     "Partner.txt3-3":"特徴：",
     "Partner.txt3-4":"経済の向上：ゲームそのものの経済と価値を向上させる新たな経済圏を創造します。",
     "Partner.txt3-5":"P2Pとブロックチェーン：P2Pとブロックチェーン技術を導入し、経済活動の領域を拡大します。",
     "Partner.txt3-13":"eスポーツの普及支援：プロチームのスポンサーやeスポーツ環境の整備など、様々な支援を行います。",
     "Partner.txt3-6":"ガイドライン：",
     "Partner.txt3-7":"Libesプラットフォームは、サービスの提供だけでなく、eスポーツの普及・支援にも積極的に参加します。 eスポーツ市場全体の発展に貢献することを目指しています。",
     "Partner.txt3-8":"お問い合わせ先：",
     "Partner.txt3-9":"プライバシーポリシー: プライバシーポリシーの詳細をご覧ください。",
     "Partner.txt3-10":"利用規約: Libesプラットフォームの利用規約についてご覧ください。",
     "Partner.txt3-11":"Libesを通じて、e スポーツ市場にさらなる可能性と活力をもたらし、e スポーツファンとプレイヤーにとってより良い未来を創造することに努めていきます。",
   
     "Partner.title4-1":"九九華聯株式会社",
     "Partner.txt4-1":"九九華聯株式会社概要：",
     "Partner.txt4-2":"九九華聯株式会社（99hualian Inc.）は、人と人とのつながりを大切にする会社です。 長野県松本市に本社を置き、東京本社はお台場にあります。2020年7月に設立され、資本金は1,000万円、従業員数は20名（グループ子会社を含む）です。九九華聯は日本と中国でオフラインの実店舗とデジタルスペースの融合を目指しています。",
     "Partner.txt4-3":"事業内容：",
     "Partner.txt4-4":".九九華聯は、日本と中国でオフラインの実店舗とデジタルの融合を実現し、日本では1,000店舗の出店を目指し、Web3.0やブロックチェーン技術を活用してデジタル空間と連動できる実店舗を構築することに取り組んで行きます。地域の商品提供の窓口として、日本と中国の商品流通を促進する。",
     "Partner.txt4-5":"九九華聯は先端技術の導入や快適な空間づくりにも力を入れており、越境ECにとどまらない新たな商品流通網の確立に取り組んでいます。さらに、各店舗で利用可能な決済アプリを開発し、フィジカルとデジタルを融合させたサービスを顧客に提供することを目指しています。",
     "Partner.txt4-6":"また、九九華聯は環境保護も重視しており、電気自動車やV2H（Vehicle to Home）のシステム導入により、脱炭素・カーボンニュートラルを積極的に推進しています。さらに、中国製の次世代農業機械を用いることで省電力化・高効率化を実現し、日本が直面している課題解決に取り組んでいきます。",
     "Partner.txt4-7":"最新のお知らせ：",
     "Partner.txt4-8":" 九九華聯は、日々のイベントや新店舗オープンなどの情報をお知らせページで提供している。 最新のお知らせによると、2023年8月に「九九華聯 札幌東店」がオープンするどうです。これまでに出店のお知らせを公開しており、過去半年の出店状況や各地の新計画なども詳しく紹介されています。",
     "Partner.txt4-9":"まとめ：",
     "Partner.txt4-10":"九九華聯株式会社は、日本と中国におけるフィジカルとデジタルの融合を推進し、出店、環境保護、テクノロジー活用など様々な事業を展開して日中間の商品流通を促進し、顧客サービスを向上させることで、新たなビジネスモデルを創造していきます。",
    
     "Partner.title5-1":"WFCクラブ：つながる、広げる、守る、作る、未来の笑顔を",
     "Partner.txt5-1":"紹介：",
     "Partner.txt5-2":"WFCクラブは、新しい技術と笑顔の普及を目指す地域活性化プロジェクトです。「つながる」「ひろげる」「まもる」ことで、地域と新技術を融合させ、日本や世界の人々が共に楽しく生活できるよう取り組んでいます。",
     "Partner.txt5-3":"コア・バリュー:",
     "Partner.txt5-4":"広げること：コミュニティを基盤に、新しい技術やアイデア、情報の発信を通じて、人と人をつなぎます。",
     "Partner.txt5-5":"つながること：国内外を問わず、伝統的なものから未来的なものまで、「ひとつになる」ことで人々の元気と笑顔を増やします。",
     "Partner.txt5-6":"守ること：伝統技術、文化、農業などを次世代に伝え、地域独自の価値を守ります。",
     "Partner.txt5-7":"活動・サービス",
     "Partner.txt5-8":"会員支援活動",
     "Partner.txt5-9":"社会貢献活動",
     "Partner.txt5-10":"北海道、東日本、関東、北日本、中部、関西、西日本、四国、沖縄など全国各地で活動を行います。",
     "Partner.txt5-11":"メンバーシップ:",
     "Partner.txt5-12":"新しい技術の普及、人と人とのつながり、伝統の継承を目指し、様々な方々の入会をお待ちしています。",
     "Partner.txt5-13":"連絡先:",
     "Partner.txt5-14":"住所：東京都港区お台場2-2-4 ザ・タワーズお台場 イーストお台場 クリニックモール3F",
     "Partner.txt5-15":"電車：「お台場海浜公園駅」より徒歩約4分。",
     "Partner.txt5-16":"我々は、WFCクラブを通じて、人々が明るい笑顔を持ち、より良い未来を一緒に創造できるよう、コミュニティにさらなる活力と活力を注入することに尽力します。。",
    
     "Partner.title6-1":"楽座 — NFTを通じて日本のアニメ文化を守る",
     "Partner.txt6-1":"紹介：",
     "Partner.txt6-2":"楽座は、代替不可能なトークン (NFT) を通じて日本のアニメ文化の重要性を守り、高めることを目的としたプラットフォームです。アニメは日本が世界に誇る文化資産です。このプラットフォームでは、日本の伝統的な浮世絵とアニメーションのセル画の歴史的重要性を紹介します。-e）世界，以及动画细胞（Cels）的历史重要性。",
     "Partner.txt6-3":"浮世絵とセル画：文化的な類似性",
     "Partner.txt6-4":"浮世絵は江戸時代に大流行し、その多様なテーマと幅広い魅力で人々を魅了しました。日本では過小評価されたことがありますが、デジタル時代では過ぎ去った時代の貴重な歴史的遺物を描写することで高く評価されています。同様に、初期のアニメーション制作で重要な役割を果たしたアニメーション・セルも、使用後に廃棄されることがよくあります。しかし、デジタル時代において、その芸術的価値と希少性が認識されるにつれ、その価値は上がり続けています。",
     "Partner.txt6-5":"楽座の重要性：",
     "Partner.txt6-6":"楽座は、アニメーション・セルの遺産を保存する必要性に取り組んでいます。これらの物理的な遺物は破壊されやすく、デジタルデータは本質的な価値と安全性を欠いています。楽座はブロックチェーン技術を通じて、デジタル資産に真正性と所有権確認を付与することを目指しており、そのため、NFTは理想的なソリューションです。楽座は、アニメーションのセルをNFT化し保護することで、世界のアート市場におけるアニメーションの地位を確保し、日本の文化財の存続を保証することを目指しています。楽座の使命は、かつて過小評価されていた浮世絵が大切な文化遺産となるまでの道のりと一致し、日本のパップカルチャとそのクリエイターの保護者となり、繁栄する経済エコシステムを育成するよう努めています。そして、NFTを通じてアニメ文化の保護と育成に尽力し、日本のアニメ遺産の保護と発展に貢献することを目指しています。 该平台的使命与浮世绘的历程相吻合，后者从曾经被低估到成为珍视的文化珍宝。楽座努力成为日本流行文化及其创作者的守护者，培育一个蓬勃发展的经济生态系统。致力于保护和培养动漫文化，楽座旨在为日本动漫遗产的保护和发展做出贡献，同时将NFT视为实现这一目标的手段。",
     "Partner.txt6-7":"アニメーション文化保護の世界を探索したく、プラットフォームのサービスについて詳しく知りたい場合は、楽座の公式 Web サイトをご覧ください。",
    
     "footer.txt-1":"住所",
     "footer.txt-2":"電話番号",
     "footer.txt-3":"Eメール",
     "footer.txt-5":"〒135-0091 東京都港区台場2-2-4"

    }