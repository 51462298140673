import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/index.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "首页",
    component: HomeView,
  },
  {
    path: "/businessArea",
    name: "业务领域",
    component: () => import("../views/businessarea.vue"),
  },
  {
    path: "/businessAreaDetails",
    name: "业务领域详情",
    component: () => import("../views/businessareaDetails.vue"),
  },
  {
    path: "/NewsCenter",
    name: "新闻中心",
    component: () => import("../views/NewsCenter.vue"),
  },
  {
    path: "/NewsCenterDetails",
    name: "新闻详情",
    component: () => import("../views/NewsCenterDetails.vue"),
  },
  {
    path: "/about",
    name: "关于我们",
    component: () => import("../views/about.vue"),
  },
  {
    path: "/adoutDetails",
    name: "关于我们详情",
    component: () => import("../views/adoutDetails.vue"),
  },
  {
    path: "/whitepaper",
    name: "白皮书",
    component: () => import("../views/whitepaper.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})
export default router;
