import { createApp,createVNode } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from '@/lang/i18n'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

  // 节流
  app.directive('throttle', {
    // 在元素的 attribute 或事件监听器被应用之前调用
    created(el, binding){
      let timer
      el.addEventListener('click',  (event: any) => {
        event && event.stopImmediatePropagation()
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          binding.value()
        },500)
      })
    }
  })
// createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
// https://fapi.aucfan-cn.com/ 
// app.config.globalProperties.$axiosUrl = 'https://netseaglobal-api.aucfan-cn.com/'//请求地址
app.config.globalProperties.$axiosUrl = 'https://fapi.aucfan-cn.com/'//请求地址
app.use(store).use(router).use(ElementPlus).use(i18n).mount('#app')