export default {
    "nav.home":"Homepage",
    "nav.businessField":"Business",
    "nav.relatedDocuments":"Related Documents",
    "nav.NewsCenter":"News center",
    "nav.aboutUs":"About Us",
    "nav.paper":"White paper",
    "new.plachodel":"No data available",
    "banner.ProjectDescription":"About the Project",
    "banner.txt1-1":'WFCA - Connecting Virtual and Real, Creating a New Digital Economy',
    "banner.txt1-2":"WFCA is committed to building a digital economy that transcends the virtual and the real  to solve the social and economic problems that Japan is currently facing, combine Japan's soft power with its hard power, and empower Japanese industries to enter the digital age.",
    "banner.txt1-3":"WFCA will create a metaverse platform that deeply integrates Japanese anime culture and traditional craftsman spirit. We will develop the anime NFT market to support creators to digitise their works through NFT, establish a virtual shopping mall to sell high-quality products from all over Japan, and open offline physical stores  to form an O2O closed loop. ",
    "banner.txt1-4":"Meanwhile, WFCA is  applying to become a blockchain bank that not only supports crypto assets, but also provides complete legal tender account services. We will also build financial infrastructures such as exchanges, asset custody platforms, and asset management terminals.",
    "banner.txt1-5":"The goal of WFCA is to become a bridge between the virtual world and the real world, creating a new digital economy system. We welcome every creator and product producer to join this new digital ecosystem. Let's step into the new era of Web3 together!",
    "banner.ProjectPurpose":"Project purpose",
    "banner.txt2-1":"Relying on cutting-edge technologies such as blockchain, digital assets, and Metaverse, the WFCA project is committed to building a digital world that integrates virtual reality. Here, Japan's traditional culture, industry, and art can be digitized and innovatively disseminated in the form of  Metaverse, NFT, and so on.  At the same time, we will also create a business model that connects virtual and reality, so that users can not only enjoy the experience of digital space, but also obtain physical goods and services. WFCA hopes to promote the Japanese economy to develop new growth drivers through digital empowerment, and promote the exchange and mutual learning of world cultures through digital platforms. Let us look forward to a digital future full of imagination and innovation.",
    "banner.DevelopmentVision":"Vision",
    "banner.txt3-1":"WFCA is committed to creating a safe and open virtual space, which brings together the rich cultural connotations of Japan and connects creators and users from all over the world. WFCA will be an immersive virtual content community where users can work, learn, and entertain; WFCA will also be a reality-oriented digital empowerment platform that empowers traditional industries through innovative models and expands economic growth momentum. We welcome more partners to work with us to build this digital world that integrates virtual and real, and let us share the infinite possibilities brought by blockchain and digital technology.",
    "home.numSpice":"The digital asset",
    "home.numTitle":" revolution will be tokenized in the future",
    "home.numCenter":"The vision of WFCA is to create a blockchain-driven digital economic circle, connect virtual assets and physical assets, and realize the deep integration of the digital world and the physical world.",
    "NextGeneration":"Next generation cryptocurrency exchange",
    "NextGeneration.txt1":"Blockchain is the technical foundation of the WFCA project. WFCA uses blockchain technology to build a trust mechanism to ensure the authenticity and provability of virtual assets.",
    "NextGeneration.txt2":"WFCA will use NFT technology on a large scale to endow virtual assets with uniqueness and anchor them with physical assets to realize the scarcity of virtual assets.",
    "NextGeneration.txt3":"WFCA will make deep use of metaverse technology to connect the virtual space and the real space, and realize the connection between the virtual world and the real world.",
    "NextGeneration.title1":"blockchain",
    "NextGeneration.title3":"Metaverse",
    "businessField.BusinessOverview":"Business overview",
    "businessField.title1":"5G business",
    "businessField.title2":"Carbon neutral",
    "businessField.title3":"Store development",
    "businessField.title4":" New energy vehicles",
    "businessField.title5":"Metaverse app",
    "businessField.title6":"Animation IP",
    "businessField.title7":"Virtual assets",

  

    "Business.txt1-1":"The WFCA is conducting in-depth research into the application of 5G technology in the agricultural sector, with the goal of realising efficient, precise and sustainable smart agriculture.",
    "Business.model1-1":"5G has attracted much attention as a next-generation communication system.",
    "Business.model1-2":"5G empowers every industry to be hidden, and agriculture is no exception.",
    "Business.model1-3":"Various fields are also expected to develop significantly with the development of 5G.",
    "Business.model1-4":"5G Agricultural Applications",
    "Business.model1-5":"〇 Automated, Unmanned agricultural machinery for super energy saving.",
    "Business.model1-6":"Under remote monitoring, expanding the types of agricultural machinery for field operations, achieving unmanned operations on-site.",
    "Business.model1-7":"〇 Data Precision",
    "Business.model1-8":"By collecting growth data and field environment information from agricultural machinery, drones, and other sources, as well as production, market, and other data, we have the capability to analyze planting plans and optimize farm management practices based on data insights. This enables us to achieve a waste-free, profitable, and sustainable agriculture by allowing the cultivation and harvesting of only the required quantities when searching for desired crops.",
    "Business.model1-9":"Advantages of 5G in Agriculture",
    "Business.model1-10":"〇 Achieving Profitable Agriculture through Increased Productivity",
    "Business.model1-11":"- Producing a specified quantity of crops within a given timeframe.",
    "Business.model1-12":"- Increasing yield and improving taste per unit area.",
    "Business.model1-13":"- Covering a large area with fewer labor requirements.",
    "Business.model1-14":"- Implementing data-based precision fertilization to minimize waste and reduce the use of chemical fertilizers and pesticides.",
    "Business.model1-15":"〇 Light Industry and Energy Saving",
    "Business.model1-16":"- Achieving work reform by eliminating heavy manual labor through automation in agricultural machinery. ",
    "Business.model1-17":"- The types of tasks that can be handled by autonomous agricultural machinery have increased, reducing the workload.",
    "Business.model1-18":"〇 Minimizing environmental load",
    "Business.model1-19":"- Optimizing fertilizer, pesticide and water usage through data-based farming, suitable for organic farming.",
    "Business.model1-20":"〇 Maintaining the multifunctionality of agriculture.",
    "Business.model1-21":"- Promoting the enlargement of scale and making it possible to utilize abandoned farmland.",
    "Business.model1-22":"- Achieving sustainable agriculture capable of resisting climate change.",
    "Business.model1-23":"01",
    "Business.model1-24":"02",

    "Business.txt1-2":"The WFCA is planning the construction of '99hualian' retail shops in Japan and China, with the goal of becoming a new shopping platform that connects the virtual world with the real world.",
    "Business.model2-1":"Project Introduction",
    "Business.model2-2":"Creating a Sense of Belonging and Everyday Life",
    "Business.model2-3":"- A store that easily consolidates various content in one place.",
    "Business.model2-4":"- A space that can be easily visited by many people, regardless of age or gender.",
    "Business.model2-5":"- A bright, open space surrounded by greenery without elaborate decoration to relax in.",
    "Business.model2-6":"- A place where everything can be done with a smartphone and does not feel cramped even after spending a whole day.",
    "Business.model2-7":"‧ - Creating a comfortable place for everyone to spend time.",
    "Business.model2-8":"‧ - Customers' enjoyment can be seen even from passing cars.",
    "Business.model2-9":"‧ - There is a wooden deck outside",
    "Business.model2-10":"and there are places to sit on the lawn.",
    "Business.model2-11":"- The entire Japan is in your hands.",
    "Business.model2-12":"‧ - Placing a smartphone on famous scenic spots in Japan allows you to see local specialties from all over the country. You can easily view and purchase what you want.",
    "Business.model2-13":"‧ - A one-stop-shop to travel all over Japan.",
    "Business.model2-14":"- A new generation of convenient stores that sell goods from all over Japan. It can also be used as a community space.",
    "Business.model2-15":"- It can also be used as a community space",
    "Business.model2-16":"‧ - Young men and women come together to relax and exchange various information about Japan's islands. You can feel like you are traveling.",
    "Business.model2-17":"The entire Japan is in front of you.",
    "Business.model2-18":"- Using VR glasses to place your finger on a map of Japan on the wall allows you to see the location.",
    "Business.model2-19":"- You can shop in the Metaverse space.",
    "Business.model2-20":"- A new generation of convenient stores where you can easily purchase various goods from all over Japan.",            
    "Business.model2-21":"- By using facts that can see specific locations, you can conduct online meetings and easily appreciate the scenery you want to see like at a convenient store.",
    "Business.model2-22":"Business Model",
    "Business.model2-23":" A new business model using blockchain and NFT.",
    "Business.model2-24":" Using DDE FINTECH HOLDING's unique blockchain technology for digital payments, we promote shopping and economic distribution.",
    "Business.model2-25":"- Additionally, we support the production, distribution, and planning of content using NFT on the NFT platform 'DMN.'",
    "Business.model2-26":"‧ Partnership with China Hualean.",
    "Business.model2-27":" We will build an infrastructure service to smoothly provide high-quality and reasonable products to circulate in Japan and support the export of valuable Japanese products to China. In addition, we will promote the attraction and consumption of Chinese tourists through entry business.",
    "Business.model2-28":"‧ Collaboration with 99 Chinese stores and communities nationwide.",
    "Business.model2-29":"We support sharing and distributing unique products and services from each prefecture to stores and members nationwide in collaboration with 99 Chinese stores and the WFC club across the country.",

    "Business.txt1-3":"The WFCA is building a unique digital metaverse where users can control virtual alters and communicate and interact with other users.",
    "Business.model3-1":"Metaverse is a combination of 'Meta' (beyond) and 'Universe.' It is a coined term that refers to a virtual space on the Internet where users can freely operate their avatar - another self - and interact with others. It is a virtual community that provides various services and content. In the virtual universe, we will offer games and shops featuring proud books, anime, and real-world products, and expand the economic zone to create a unique economic area.",
    "Business.model3-2":"Ani Live",
    "Business.model3-3":"Live Music x Metaverse",
    "Business.model3-4":"- Participate in live performances using NFT tickets.",
    "Business.model3-5":"- After the live performance, the NFT can be kept as a commemorative item. (Commemorative NFT)",
    "Business.model3-6":"- NFT ticket holders have benefits",

    "Business.txt1-4":"The WFCA will support mainstream cryptocurrencies such as BTC and ETH, and will gradually become compatible with more public chains as needed.",
    "Business.model4-1":"Acquiring diamond mining rights in Sierra Leone, Africa. Binding the equivalent of 30,000 yen worth of diamonds with blockchain.",                   
    "Business.model4-2":"Adamant Coin can be exchanged for WFCA at a ratio of 1:1.",
    "Business.model4-3":"Mined from Sierra Leone mines.",
    "Business.model4-4":"A blockchain based on 30,000 yen that can be exchanged with ADTD. ",

    "Business.txt1-5":"The WFCA is promoting the construction of a carbon sink forest in the Reed Park for carbon neutrality and carbon asset management.",
    "Business.model5-1":"What is Jiwu?",
    "Business.model5-2":"Jiwu is a material that absorbs a large amount of carbon dioxide and effectively suppresses global warming",
    "Business.model5-3":"bamboo",
    "Business.model5-4":"tropical rainforest",
    "Business.model5-5":"rice straw",
    "Business.model5-6":"and corn stalks",
    "Business.model5-7":"The net weight (t/acre)",
    "Business.model5-8":"carbon dioxide absorption (t/acre)",
    "Business.model5-9":"Oxygen output (t/mu)",
    "Business.model5-10":" It is important to note that agricultural waste and residue have lower production volumes and densities, resulting in fewer exports. Due to high transportation costs, biomass energy cannot be classified as plant-based energy. ",
    "Business.model5-11":"Rochiku",
    "Business.model5-12":"establishes a large dataset for the bamboo industry. Through this data system, it provides services to growers, managers, processors, and buyers.",
    "Business.model5-18":"Product trends",

    "Business.txt1-6":"The WFCA has teamed up with Ark Motors, the UAE's first electric vehicle manufacturer, to produce environmentally friendly electric vehicles.",
    "Business.model6-1":"Fangkuang Automobile.",
    "Business.model6-2":"As the first electric vehicle manufacturer in Abu Dhabi, UAE, we will manufacture and sell environmentally friendly electric vehicles. Ninety-nine percent will expand to China and other parts of the world as sales outlets.",
     "Business.model6-3":"V2H  (Vehicle to Home)",
     "Business.model6-4":"system is designed to allow electric vehicles (EVs) or plug-in hybrid electric vehicles (PHEVs) to use power at home. We will offer this V2H system bundled with our EV vehicles through our partnership with 99 Flowers Rengo to contribute to the environment.",
    "Business.txt1-7":"The WFCA has created 'Animeta', an NFT trading platform specialising in anime.",
    "Business.model7-1":"ANIMETA is a decentralized NFT trading platform that will be applied to the anime industry, combining related IPs, works, merchandise, and NFTs. It aims to create an open ecosystem for creating and trading tokens. In the post-pandemic era, the physical limitations of the anime industry have become a bottleneck for its development. ANIMETA platform seeks to overcome this by utilizing NFT technology and providing a free trading experience to convert each anime work into an NFT. It enables coloration, linking each anime work through the issuance of NFTs, which can be traded as assets, domain names, and derivatives on the platform, aiming to achieve interoperability with assets outside of the platform. ",
    "Business.model7-2":"Platform display",

    "aboutUs.Introduction":"Board member Profiles",
    "aboutUs.Team":"Team member Profiles",
    "ababoutUs.Partner":"Partner",
    "about.name-1":"CEO",
    "about.txt1-1":" CEO of Tokyo Isho  (1980-1987)",
    "about.txt1-2":'Representative works:Tokyo Disneyland “It’s a Small World,”',
    "about.txt1-3":"CEO of Status Land Co., Ltd. (1988 - 1992) ",
    "about.txt1-4":"NHK Joho Network Inc. and paid FAX services.",
    "about.txt1-5":"Executive Vice President of Spike Co., Ltd. (1993 - 2001)",
    "about.txt1-6":'Game Production Works (for PlayStation): "Lupin the 3rd Chronicles"',
    "about.txt1-7":"Movie producer works:Director Seijun Suzuki “Pistol Opera” Venice Film Festival Special Jury Prize",
    "about.txt1-8":"CEO  of  Dream Eggs Co., Ltd. (2001 - 2017)",
    "about.txt1-9":"Mobile phone content planning and production “Cataloger business”",
    "about.txt1-10":"Collaboration with Taito Corporation.(Largest EC video distribution business in Japan at the time)",
    "about.txt1-11":"Provided content to Leo Palace",
    "about.txt1-12":"TV Tokyo “Kappa maki” animation 132-episode production",
    "about.txt1-13":"DVD production 38 titles",
    "about.txt1-14":"Publishing production “Beatles Chronicles,” “Ronaldinho’s World”",
    "about.txt1-15":"CEO of 99 Hualian Inc (present) ",
    "about.name-2":"CFO",
    "about.txt2-1":" Bachelor of Commerce, Ichijo University, Tokyo (1997)",
    "about.txt2-2":"CEO(1997 - 1998)",
    "about.txt2-3":"Manager of Nomura Securities' New York headquarters.",
    "about.txt2-4":"Manager of Tokyo Headquarters.",
    "about.txt2-5":"Director of International Finance Department, Tokyo Headquarters.",
    "about.txt2-6":"CEO of Bangkok Representative Office.",
    "about.txt2-7":"Nomura Haiphong Industrial Park",
    "about.txt2-8":"SSD Japan Corporation (1998 - 2015)",
    "about.txt2-9":"(Company name changed to Ultimelit Corporation) CEO.",
    "about.txt2-10":"CEO of Evertech Corporation (2010 - 2015)",
    "about.txt2-11":"CEO of Next Coin Corporation (2018 - present)",
    "about.name-3":"Chief Marketing Officer",
    "about.txt3-1":"Bachelor of Economics, Nihon University (1989)",
    "about.txt3-2":"CEO of IDEA Research Institute Co., Ltd.  (1989 - 2002)",
    "about.txt3-3":"CEO of Tokyo Daiichi Co., Ltd. (2002 - 2015)",
    "about.txt3-4":"CEO of IDEA Co., Ltd. (2015 - 2018)",
    "about.txt3-5":"System Department Director of  Next Coin Co., Ltd. (2018 - present)",
    "about.txt3-6":"Japan Crypto Asset Business Association (to date) NFT Division",

    "about.name-4-1":"Senior IT Engineer",
    "about.name-4-2":"PhD of Computer Science",
    "about.txt4-1":"Has extensive experience in the field of Artificial Intelligence and Internet of Things .",
    "about.txt4-2":"As a researcher and engineer, Dr. Lim has recently started working on blockchain technology, focusing on low-level challenges of blockchain such as consensus and cryptography.",

    "about.name-5-1":"Senior Software Engineer",
    "about.txt5-1":"After years of working as a freelance web developer, became interested in cryptocurrencies and started developing  dApp two years ago",

    "about.name-6-1":"Software Engineer",
    "about.txt6-1":"A recent graduate who developed a keen interest in the trustless architecture of blockchain technology during college, spent most of his time researching blockchain and is currently part of the dApp development team.",
    
    "about.name-7-1":"Software Engineer",
    "about.txt7-1":"Over 2 years of experience in Solidity development. Syhami was a core member of the BcVote project, which participated in CPAC 2020. Syhami is an expert in backend technology.",
    "see.more":"View More",
    "current.location":"Current Location",
    "Partner.Details":"Partner",
    

    "Partner.title1-1":"Ark Electric Vehicles - The UAE's Eco-Friendly Choice:",
    "Partner.txt1-2":"Profile :",
    "Partner.txt1-3":"Ark Electric Vehicles is a new brand of electric vehicles launched in Abu Dhabi, UAE, which is notable for its eco-friendly performance and luxury features. As the first electric vehicle manufacturer in the UAE, Ark is committed to delivering an eco-friendly, high-quality travelling experience to passengers in the cities of the future. ",
    "Partner.txt1-4":"Features:",
    "Partner.txt1-5":"VIP - Taxi: Known as the 'green' taxi, the Ark EV is all-electric, emission-free, haze-free and environmentally friendly.",
    "Partner.txt1-6":"Neighbourhood Friendly: Adapting to the needs of the 21st century city, low noise, no pollution, providing a quiet travelling environment for city residents.",
    "Partner.txt1-7":"Ultimate Comfort: Extended safety with electric doors, VIP interior with panoramic views from all seats.",
    "Partner.txt1-8":"Interior variability: Converts to taxi, business class or VIP seating to meet different travelling needs.",
    "Partner.txt1-9":"INNOVATIVE DESIGN: 100% carbon composite, 100% electric power, curved panoramic windscreen and premium open spaces.",
    "Partner.txt1-10":"Specification:",
    "Partner.txt1-11":"Category: Farak (ARK)",
    "Partner.txt1-12":"Dimensions: L x W x H 4.65m x 1.90m x ?",
    "Partner.txt1-13":"Empty weight: 1600kg",
    "Partner.txt1-14":"Payload: 500kg",
    "Partner.txt1-15":"Range: up to 400km",
    "Partner.txt1-16":"Maximum speed: 160km/h (limited)",
    "Partner.txt1-17":"Emissions: True zero emissions",
    "Partner.txt1-18":"Battery capacity: 63 kWh",
    "Partner.txt1-19":"Founder:",
    "Partner.txt1-20":"His Highness Sheikh Mohammed bin Ahmed bin Hamdan Al Nahyan: Leader of the UAE, Founder of Ark Electric Vehicles and nephew of the President. His vision is to bring electric vehicles to the UAE's roads, providing cities with cleaner and sustainable transport options. ",
    "Partner.txt1-21":"History:",
    "Partner.txt1-22":"1-2 November 2022: Ark Electric Vehicles held a grand launch event in the UAE, showcasing the new electric models and attracting attention from all walks of life, including the media and government officials.",
    "Partner.txt1-23":"Contact Details:",
    "Partner.txt1-24":"Email: info@arkmotors.tech",
    "Partner.txt1-25":"Address: 15th Floor, Millennium Plaza Hotel, Dubai, UAE",
    "Partner.txt1-26":"Ark Electric Vehicles is the first electric vehicle manufacturer in the Middle East to bring a new experience of eco-friendly mobility. Stay tuned! (Copyright © ARK MOTORS & TECHNOLOGY L.L.C.)",


    "Partner.title2-1":"DDE",
    "Partner.txt2-1":"DDE Purpose：",
     "Partner.txt2-2":"To expand the possibilities of society by connecting people and businesses with the power of technology.",
     "Partner.txt2-3":"With changing times and unprecedented events, technological advancements, things are about to change dramatically for society and industry. Nowadays, the value of a company cannot be measured by performance alone.",
     "Partner.txt2-4":"I believe that if only our company wins, as in the past, and if we only pursue economic value, we will never be able to become a sustainable and loved existence.",
     "Partner.txt2-5":"Value to customers, value to partners, and value to society and the environment. It is necessary to improve all of these in a comprehensive manner. Instead of being satisfied with short-term figures, we will develop people, build businesses, and create a sustainable future.",
     "Partner.txt2-6":"That's our commitment at DDE.",
     "Partner.txt2-7":"Top Message:",
     "Partner.txt2-8":"Our vision at DDE is to find value in information and see it as a source of business innovation, creating a new future through our contribution to society. Regularly and flexibly adopting the latest technologies and ideas, we conduct our business in an advanced way and actively promote innovations for the next generation.",
     "Partner.txt2-9":"I firmly believe that our activities can have a positive impact on the future of society as a whole. Precisely because it is a modern society where change is intense, we are unafraid to take on the challenge of constantly pursuing new possibilities and realising what administration and large corporations are unable to do. Also, I think it is our mission to realise impactful social contributions that no one has been able to do so far.",
     "Partner.txt2-10":"Challenges are etched in our DNA. I want to be the leading company (change agent) that constantly demonstrates new ways of contributing to society through various activities.",
     "Partner.txt2-11":"Not to be satisfied with short-term success, but to evolve in order to keep growing and improving, and to achieve a better future. We look at our business from a long-term perspective and think about how to create a sustainable future.",
     "Partner.txt2-12":"We at DDE will continue to evolve as a group of professionals in the field of technology that builds a sustainable future through innovation and social contribution.",
     "Partner.txt2-13":"Company Profile",
     "Partner.txt2-14":"DDE FINTECH HOLDING LTD.",
     "Partner.txt2-15":"Name in English",
     "Partner.txt2-16":"DDE FINTECH HOLDING LTD",
     "Partner.txt2-17":"Established",
     "Partner.txt2-18":"1 October 2003",
     "Partner.txt2-19":"Representative Director",
     "Partner.txt2-20":"Kenji Okushima representative",
     "Partner.txt2-21":"Capital",
     "Partner.txt2-22":"50 million yen (as of the end of December 2019)",
     "Partner.txt2-23":"Number of Employees",
     "Partner.txt2-24":"50 (as of the end of April 2020)",
     "Partner.txt2-25":"Location of Head Office",
     "Partner.txt2-26":"2-2-4 Odaiba, Minato-ku, Tokyo 135-0091",
     "Partner.txt2-27":"Company Name",

     "Partner.title3-1":"Libes - Creating a New Economic Circle for eSports",
     "Partner.txt3-1":"Profile :",
     "Partner.txt3-2":"Libes is a platform that aims to create a new economic ecosystem for the eSports market, enhancing the economics and value of eSports. In addition to the traditional eSports economic model, we have incorporated P2P elements and blockchain into the platform to expand the economic activity options, provide financial support for eSports players, and bring a whole new level of fun to our users. This is achieved through a combination of sign-ups, giveaways and NFTs.",
     "Partner.txt3-3":"Feature:",
     "Partner.txt3-4":"ENHANCED ECONOMY: Creating a new economic circle that enhances the economy and value of gaming itself.",
     "Partner.txt3-5":"P2P and Blockchain: Introducing P2P and Blockchain technology to expand the field of economic activities.",
     "Partner.txt3-13":"Support the popularity of e-sports: Provide support in various aspects, including sponsoring professional teams and improving the e-sports environment.",
     "Partner.txt3-6":"Guidelines for use:",
     "Partner.txt3-7":"The Libes platform not only provides services, but also actively participates in the popularisation and support of gaming. Our goal is to contribute to the development of the entire gaming market.",
     "Partner.txt3-8":"Contact details:",
     "Partner.txt3-9":"Privacy Policy: Learn more about our privacy policy.",
     "Partner.txt3-10":"Terms of Use: Learn more about the terms of use of the Libes platform.",
     "Partner.txt3-11":"Through Libes, we endeavour to bring more possibilities and vitality to the esports market and create a better future for esports fans and players. ",
   
     "Partner.title4-1":"99hualian Co.",
     "Partner.txt4-1":"Profile of 99hualian Inc:",
     "Partner.txt4-2":"99hualian Inc  is a company that values human connection. It is headquartered in Matsumoto City, Nagano Prefecture, Japan, with its Tokyo headquarters in Odaiba. Founded in July 2020, it has registered capital of 10 million yen and 20 employees (including group subsidiaries). The company has realised the integration of offline physical shops and digital spaces in Japan and China.",
     "Partner.txt4-3":"Business Content:",
     "Partner.txt4-4":".99hualian is committed to realising the convergence of offline brick-and-mortar shops and digital spaces in Japan and China, with a goal of opening 1,000 shops in Japan. In addition, they are building physical shops that interact with digital spaces using Web 3.0 and blockchain technology. This helps to serve as a window for regional product offerings and facilitates the exchange of Japanese and Chinese goods.",
     "Partner.txt4-5":"99hualian also focuses on the Profile of advanced technology and the creation of comfortable spaces, and is committed to establishing a new merchandise distribution network that goes beyond cross-border e-commerce. In addition, they are developing payment applications that can be used at each shop to provide customers with services that blend the physical and digital.",
     "Partner.txt4-6":"The company also places importance on environmental conservation, and is actively promoting the cause of decarbonisation and carbon neutrality through measures such as the Profile of electric vehicles and V2H (Vehicle to Home). In addition, they are contributing to solving the problems they are currently facing by promoting smart agriculture with next-generation agricultural machinery made in China that is energy-efficient and energy-saving.",
     "Partner.txt4-7":"Latest Announcement:",
     "Partner.txt4-8":" KCW provides a notification page with information such as daily events and new shop openings. The latest news shows that in August 2023, 'Kukui Hualian Sapporo East' opened. The shop's opening announcement was previously published, and details of shop openings over the past six months and new plans for each region were also provided.",
     "Partner.txt4-9":"Summary:",
     "Partner.txt4-10":"Kukkyu Hualian Co., Ltd. is committed to promoting the fusion of the physical and digital worlds in Japan and China, and is engaged in a variety of businesses, including shop opening, environmental protection, and the use of technology. The company has created new business models by facilitating the exchange of goods and improving customer service between the two countries.",
    
     "Partner.title5-1":"WFC Club: Connecting, Spreading, Protecting, Creating Future Smiles",
     "Partner.txt5-1":"Profile :",
     "Partner.txt5-2":"WFC Club is a regional revitalisation project that aims to promote new technologies and smiles. By connecting, spreading, and protecting, we work to integrate communities with new technologies so that people in Japan and around the world can enjoy life together.",
     "Partner.txt5-3":"Core Value:",
     "Partner.txt5-4":"Dissemination: Based on community, we connect people through the dissemination of new technologies, ideas and information.",
     "Partner.txt5-5":"Connect: Whether local or foreign, traditional or futuristic, we increase people's energy and smiles through 'unity'.",
     "Partner.txt5-6":"Preservation: We pass on traditional skills, culture, agriculture, etc. to the next generation, preserving the unique values of the community.",
     "Partner.txt5-7":"Activities and Services:",
     "Partner.txt5-8":"Member support activities",
     "Partner.txt5-9":"Social Contribution Activities",
     "Partner.txt5-10":"Activities are held throughout Japan, including Hokkaido, East Japan, Kanto, North Japan, Central Japan, Kansai, West Japan, Shikoku and Okinawa.",
     "Partner.txt5-11":"Membership:",
     "Partner.txt5-12":"We welcome people from all walks of life to join our club to spread new technologies, connect people with people, and preserve traditions.",
     "Partner.txt5-13":"Contact address.",
     "Partner.txt5-14":"Address: 3F, East Odaiba Clinic Mall, The Towers Odaiba, 2-2-4 Odaiba, Minato-ku, Tokyo",
     "Partner.txt5-15":"Train: 4-minute walk from Odaiba Kaihin Koen Station.",
     "Partner.txt5-16":"Through the WFC Club, we are committed to injecting more life and vitality into the community, making people smile and creating a better future together",
    
     "Partner.title6-1":"Rakuza - Preserving Japanese Anime Culture through NFT",
     "Partner.txt6-1":"RProfile:",
     "Partner.txt6-2":"Rakuza is a platform dedicated to preserving and enhancing the importance of Japanese anime culture through Non-Formalised Tokens (NFT). Anime is a proud and globally recognised cultural asset of Japan. The platform introduces the world of traditional Japanese Ukiyoe woodblock prints  and the historical importance of celluloid .",
     "Partner.txt6-3":"Ukiyo-e and Celluloid: Cultural Parallels",
     "Partner.txt6-4":"Ukiyo-e was all the rage during the Edo period, captivating the masses with its diverse themes and wide appeal. Though at some point undervalued domestically, it subsequently gained currency in the digital age for its depiction of precious historical relics of a bygone era. Similarly, celluloid, an important part of early animation production, are usually discarded after use. However, in the digital age, their value continues to climb as recognition of their artistic value and rarity increases.",
     "Partner.txt6-5":"The importance of Rakuza:",
     "Partner.txt6-6":"Rakuza  need to preserve the heritage of celluloid. These physical artefacts are vulnerable to vandalism and digital data lacks intrinsic value and security. Through blockchain technology, Rakuzaaims to empower digital assets with authenticity and ownership verification, making NFT an ideal solution. By NFTing physical celluloid and protecting them, Rakuza aims to secure their place in the global art market and ensure the continuation of Japan's cultural heritage. The platform's mission matches the journey of ukiyo-e, which has gone from being once undervalued to becoming a cherished cultural treasure. Rakuza strives to be the guardian of Japanese popular culture and its creators, fostering a thriving economic ecosystem. Dedicated to preserving and nurturing anime culture, Rakuza seeks to contribute to the preservation and development of Japan's anime heritage, and sees the NFT as a means to that end.",
     "Partner.txt6-7":"For more information, to explore the world of anime culture preservation, or to enquire about the platform's services, please visit Rakuza's official website.",

     "footer.txt-1":"Addresses",
     "footer.txt-2":"Phone number",
     "footer.txt-3":"Email",
     "footer.txt-5":"2-2-4 Odaiba, Minato-ku, Tokyo 135-0091"
    }