export default {
    "nav.home":"首页",
    "nav.businessField":"业务领域",
    "nav.relatedDocuments":"相关文档",
    "nav.NewsCenter":"新闻中心",
    "nav.aboutUs":"关于我们",
    "nav.paper":"白皮书",
    "new.plachodel":"暂无数据",
    "banner.ProjectDescription":"项目简介",
    "banner.txt1-1":"WFCA - 连接虚拟与现实,创建崭新的数字经济",
    "banner.txt1-2":"WFCA致力于构建一个跨越虚拟与现实的数字经济体,以解决日本当前面临的社会与经济难题,把日本的软实力与硬实力完美结合,赋能日本产业进入数字化时代。",
    "banner.txt1-3":"WFCA将打造一个元宇宙平台,深度融合日本的动漫文化与传统工匠精神。我们将开发动漫NFT市场,支持创作者通过NFT实现作品数字化;建立虚拟商城,销售来自日本各地的优质产品;开设线下实体店,形成O2O闭环。",
    "banner.txt1-4":"同时,WFCA正在申请成为一家区块链银行,不仅支持加密资产,也提供完整的法定货币账户服务。我们还将构建交易所、资产托管平台、资产管理终端等金融基础设施。",
    "banner.txt1-5":"WFCA的目标是成为连接虚拟世界与现实世界的桥梁,创造一个崭新的数字经济体系。我们欢迎每一位创作者、产品生产者加入这个新的数字化生态圈。让我们一起迈入Web3的新纪元!",
    "banner.ProjectPurpose":"项目宗旨",
    "banner.txt2-1":"WFCA项目依托区块链、数字资产、元界等前沿技术,致力于构建一个虚拟现实融合的数字世界。在这里,日本的传统文化、产业、艺术可以实现数字化,以Metaverse、NFT等形式进行创新传播。同时,我们还将打造连接虚拟与现实的商业模式,用户可以享受数字空间的体验之余,也可以获得实物商品和服务。WFCA希望通过数字化赋能,推动日本经济开拓新的增长动能,并以数字平台促进世界文化的交流互鉴。让我们共同期待一个充满想象和创新的数字未来。",
    "banner.txt3-1":"WFCA矢志打造一个安全开放的虚拟空间,这里汇聚了日本丰富的文化内涵,也连接着来自全球的创作者与用户。WFCA将是一个沉浸式的虚拟内容社区,用户可以在此工作、学习、娱乐; WFCA也将是一个面向现实的数字化赋能平台,通过创新模式赋能传统产业,拓展经济增长动能。我们欢迎更多合作伙伴与我们一起构建这个融合虚拟与实的数字世界,让我们共享区块链和数字技术带来的无限可能。",
    "banner.DevelopmentVision":"发展愿景",
    "home.numSpice":"数字资产",
    "home.numTitle":"革命，未来将被代币化。",
    "home.numCenter":"WFCA的愿景是打造一个区块链驱动的数字经济圈,连接虚拟资产与实物资产,实现数字世界与实体世界的深度融合。",
    "NextGeneration":"下一代加密货币交易所",
    "NextGeneration.txt1":"区块链是WFCA项目的技术基础。WFCA使用区块链技术构建信任机制,确保虚拟资产的真实性和可证明性。",
    "NextGeneration.txt2":"WFCA将大规模运用NFT技术为虚拟资产赋予唯一性,并将其与实物资产锚定,实现虚拟资产的稀缺性。",
    "NextGeneration.txt3":"WFCA将深度运用元宇宙技术,打通虚拟空间与现实空间,实现虚拟世界与现实世界的连接。",
    "NextGeneration.title1":"区块链",
    "NextGeneration.title3":"元宇宙",
    "businessField.BusinessOverview":"业务概览",
    "businessField.title1":"5G业务",
    "businessField.title2":"碳中和",
    "businessField.title3":"店面开发",
    "businessField.title4":"新能源汽车",
    "businessField.title5":"元宇宙应用",
    "businessField.title6":"动漫IP",
    "businessField.title7":"虚拟资产",
    "Business.txt1-1":"WFCA项目正在深入研究5G技术在农业领域的应用,目标是实现高效、精准、可持续的智能农业。",
    "Business.model1-1":"5G作为下一代通信系统备受关注。 ",
    "Business.model1-2":"5G赋能各行各业被隐藏，农业也不例外。",
    "Business.model1-3":"预计各领域也将随着 5G 的发展而显着发展。",
    "Business.model1-4":"5G农业应用",
    "Business.model1-5":"〇 自动化、无人农业机械超级省力",
    "Business.model1-6":"远程监控下，增加农业机械在田间作业的种类，实现现场无人化作业。",
    "Business.model1-7":"〇 数据精准",
    "Business.model1-8":"从农业机械、无人机等采集的生长数据和田间环境。信息、产量、市场数据等，以分析种植计划或基于数据的农场管理效率有能力去当寻找所需作物时实现无浪费、盈利和可持续农业，只允许种植和收获所需数量。",
    "Business.model1-9":"5G农业优势",
    "Business.model1-10":"〇 通过提高生产力实现盈利农业",
    "Business.model1-11":"‧ 在规定的时间生产规定数量的农作物。",
    "Business.model1-12":"‧ 提高单位面积的产量和口感。",
    "Business.model1-13":"‧ 一个人可以覆盖广阔的区域。",
    "Business.model1-14":"‧ 基于数据的无废弃物施肥，减少化肥和农药的使用。",
    "Business.model1-15":"〇 轻工、省力",
    "Business.model1-16":"‧ 通过农业机械等的自动化来消除繁重的工作。实现工作方式改革。",
    "Business.model1-17":"‧ 自动驾驶农业机械可以处理的工作类型增加了，减少工作量。",
    "Business.model1-18":"〇 减少环境负荷",
    "Business.model1-19":"‧ 通过基于数据的农业优化肥料、农药和水的使用，适合有机农业。",
    "Business.model1-20":"〇 维护农业多方面功能",
    "Business.model1-21":"‧ 促进规模扩大，废弃农田的利用成为可能。",
    "Business.model1-22":"‧ 实现抵御气候变化的可持续农业。",
    "Business.model1-23":"01",
    "Business.model1-24":"02",


    



    "Business.txt1-2":"WFCA项目正在日本和中国规划建设“九九华联”零售店,目标是成为连接虚拟世界与现实世界的全新购物平台。",
    "Business.model2-1":"项目简介",
    "Business.model2-2":"创造日常生活和归属感",
    "Business.model2-3":"‧ 可以轻松地将各种内容集中在一处的商店。",
    "Business.model2-4":"‧ 无论年龄或性别，许多人都可以轻松参观的空间。",
    "Business.model2-5":"‧ 没有华丽的装饰，可以在绿色环绕的明亮开放的空间中放松身心。",
    "Business.model2-6":"‧ 还有一个可以用智能手机做任何事情的地方，即使度过一整天也不会感到局促。",
    "Business.model2-7":"‧ 为每个人创造一个舒适的度过时光的场所。",
    "Business.model2-8":"‧ 即使在驶过的汽车内也能看到顾客们的乐趣。",
    "Business.model2-9":"‧ 外面有木甲板露台。",
    "Business.model2-10":"‧ 里面的草地上有可以坐的地方。",
    "Business.model2-11":"整个日本都在你的手中",
    "Business.model2-12":"‧ 将智能手机放在日本列岛的名胜古迹上，就能看到各个地区的特产。您可以轻松查看并购买您想要的东西。",
    "Business.model2-13":"‧ 一处就能游遍日本。",
    "Business.model2-14":"‧ 销售日本全国商品的新一代便利店。",
    "Business.model2-15":"‧ 除了购物之外，还可以用作社区空间。",
    "Business.model2-16":"‧ 年轻男女聚集在一起，一边放松一边交换有关日本列岛的各种信息。你可以有一种旅行的感觉。",
    "Business.model2-17":"整个日本都在你的眼前",
    "Business.model2-18":"‧ 使用 VR 眼镜将手指放在墙上的日本地图上，就可以看到位置。",
    "Business.model2-19":"‧ 您可以在Metaverse空间购物。",
    "Business.model2-20":"‧ 可以轻松购买日本各地商品的新一代便利店。",            
    "Business.model2-21":"‧ 利用可以看到特定地点的事实进行在线会议，走遍日本各地那些不能走太远的地方，您可以像便利店一样轻松欣赏自己想看的风景。",
    "Business.model2-22":"商业模式",
    "Business.model2-23":"‧ 利用区块链和NFT的新商业模式",
    "Business.model2-24":"使用“DDE FINTECH HOLDING”独特的区块链技术的数字支付，通过手段促进购物支付和经济分配。",
    "Business.model2-25":"此外，我们还支持在NFT平台“DMN”上使用NFT进行内容的制作和分发以及策划。",
    "Business.model2-26":"‧ 与中国华联的合作",
    "Business.model2-27":"能够顺利地提供优质合理的产品在日本的流通的基础设施服务我们将建造它并支持将有价值的日本产品销往中国。此外，我们还将通过入境业务促进中国游客的吸引力和消费。",
    "Business.model2-28":"‧ 与全国99家花店及社区合作",
    "Business.model2-29":"与全国九十九家华人店及WFC俱乐部合作，我们支持将各都道府县的特色产品和服务分享和分发给全国的商店和会员。",


    "Business.txt1-3":"WFCA项目正在构建独特的数字元界,用户可以在这里控制虚拟分身,与其他用户进行交流和互动。",
    "Business.model3-1":"Metaverse是“Meta” (超越)和  “Universe” (世界)的组合。这是一个创造的词，Metaverse。它是互联网上的一个虚拟空间，用户可以在其中自由地操作自己的另一个自我(称为 “头像”) 这是一个人们可以行动和交流的空间。其中，它是一个提供各种服务和内容的虚拟社区。在虚拟宇宙中，日子我们将提供书籍引以为傲的动漫和真实产品的游戏和商店等内容，并扩大经济区打造特色经济区。",
    "Business.model3-2":"阿尼现场",
    "Business.model3-3":"现场音乐 x 元宇宙",
    "Business.model3-4":"‧ 使用NFT门票参加现场表演",
    "Business.model3-5":"‧ live结束后，可以作为纪念NFT持有 (纪念NFT)",
    "Business.model3-6":"‧ NFT 门票持有者有福利",

    "Business.txt1-4":"WFCA项目将支持主流加密货币如BTC和ETH,并根据需要逐步兼容更多公链。",
    "Business.model4-1":"取得非洲塞拉利昂钻石矿采矿权。相当于3万日元的钻石与区块链绑定。",                   
    "Business.model4-2":"Adamant Coin可以按1:1的比例兑换WFCA。",
    "Business.model4-3":"从塞拉利昂矿山开采。",
    "Business.model4-4":"以3万日元为基础的区块链，可与 ADTD 互换。",


    "Business.txt1-5":"WFCA项目正在推进芦苇园区碳汇林建设,用于碳中和与碳资产管理。",
    "Business.model5-1":"什么是吉武？",
    "Business.model5-2":"吸收大量二氧化碳、有效抑制全球变暖的材料",
    "Business.model5-3":"芦竹",
    "Business.model5-4":"热带雨林",
    "Business.model5-5":"稻秆",
    "Business.model5-6":"玉米秆",
    "Business.model5-7":"净重（t/亩）",
    "Business.model5-8":"二氧化碳吸收（t/亩）",
    "Business.model5-9":"氧气输出量（t/亩）",
    "Business.model5-10":"注：农业废弃物及残渣产量低，密度低出口少。由于运输成本高，生物质能源和它不能称为植物能源",
    "Business.model5-11":"碳信用额  (Rochiku)",
    "Business.model5-12":"建立芦竹产业大数据，通过大数据系统，种植者、管理者、加工者、采购商 为您提供服务。它还提供全产业链的数据服务。",
    "Business.model5-18":"产品趋势",

    "Business.txt1-6":"WFCA项目携手阿联酋第一家电动汽车制造商Ark Motors,致力于生产环保的电动汽车。",
    "Business.model6-1":"方舟汽车",
    "Business.model6-2":"作为阿联酋阿布扎比的第一家电动汽车制造商，我们将制造和销售环保电动汽车。百分之九十九将作为销售网点扩展到中国和世界其他地区。",
     "Business.model6-3":"V2H  (Vehicle to Home)",
     "Business.model6-4":"是一种电动汽车(EV)或插电式混合动力汽车(PHV)在家中使用电力的系统。九十九朵花Rengo将这款V2H和EV车辆打包出售，我们将为环境考虑做出贡献。",

    "Business.txt1-7":" WFCA项目打造的“Animeta”是一款专注动漫的NFT交易平台。",
    "Business.model7-1":"去中心化NFT交易平台“ANIMETA”将应用于动漫行业结合相关IP、作品、周边和NFT、动漫NFT建立一个开放的生态系统来创建和交易代币这是一件事。ANIMETA，后疫情时代，动漫产业的物理限制已经成为产业发展的瓶颈。我觉得为此，ANIMETA平台将利用NFT技术和免费交易体验将每部动漫作品转换为NFT。启用色彩学。每个动漫作品通过发行NFT关联起来可以作为资产、域名和平台的衍生品进行交易旨在实现平台外资产的互操作性。",
    "Business.model7-2":"平台展示",

    "aboutUs.Introduction":"董事会成员介绍",
    "aboutUs.Team":"团队成员介绍",
    "ababoutUs.Partner":"合作伙伴",
    "about.name-1":"首席执行官",
    "about.txt1-1":"东京石舍株式会社（1980年 - 1987年）CEO",
    "about.txt1-2":'代表作品：东京迪士尼乐园的 "小小世界"',
    "about.txt1-3":"Status Land 株式会社（1988年 - 1992年）CEO兼总裁",
    "about.txt1-4":"NHK信息网络株式会社及付费传真服务",
    "about.txt1-5":"Spike 株式会社（1993年 - 2001年）执行副总裁",
    "about.txt1-6":'游戏制作作品（PlayStation）："鲁邦三世编年史"',
    "about.txt1-7":'电影制作作品：导演铃木清顺的 "枪手歌剧" 威尼斯电影节特别评审团奖',
    "about.txt1-8":"Dream Eggs 株式会社（2001年 - 2017年）CEO兼总裁",
    "about.txt1-9":'移动电话内容策划和制作 "目录业务"',
    "about.txt1-10":"与太阳神公司合作（当时日本最大的电子商务视频分发业务）",
    "about.txt1-11":"为Leo Palace提供内容",
    "about.txt1-12":'电视东京 "Kappa卷" 动画132集制作',
    "about.txt1-13":"DVD制作 38个标题",
    "about.txt1-14":'出版制作 "披头士编年史"，"罗纳尔迪尼奥的世界"',
    "about.txt1-15":"99华联株式会社（至今）CEO兼总裁",

    "about.name-2":"首席财务官",
    "about.txt2-1":"东京一椏大学商学学士（1997年）CEO",
    "about.txt2-2":"野村证券株式会社（1997年 - 1998年）管理者，",
    "about.txt2-3":"野村证券纽约总部管理者，",
    "about.txt2-4":"东京总部管理者，",
    "about.txt2-5":"东京总部国际金融部总监，",
    "about.txt2-6":"曼谷代表处CEO，",
    "about.txt2-7":"野村海防工业园区",
    "about.txt2-8":"SSD Japan 株式会社（1998年 - 2015年）",
    "about.txt2-9":"（公司名称更改为Ultimelit株式会社）CEO",
    "about.txt2-10":"Evertech 株式会社（2010年 - 2015年）CEO",
    "about.txt2-11":"Next Coin 株式会社（2018年 - 至今）CEO",

    "about.name-3":"首席营销官",
    "about.txt3-1":"日本大学经济学士（1989年）",
    "about.txt3-2":"IDEA研究所株式会社（1989年 - 2002年）CEO",
    "about.txt3-3":"东京第一公司株式会社（2002年 - 2015年）CEO",
    "about.txt3-4":"IDEA株式会社（2015年 - 2018年）CEO",
    "about.txt3-5":"Next Coin 株式会社（2018年 - 至今）系统部门主任",
    "about.txt3-6":"日本加密资产业务协会（至今）NFT部门",

    "about.name-4-1":"高级IT工程师",
    "about.name-4-2":"计算机科学博士",
    "about.txt4-1":"在人工智能和物联网领域拥有拥有丰富的经验 。",
    "about.txt4-2":"作为研究员和工程师，Dr. Lim最近开始研究区块链技术，关注区块链的低层次挑战，如共识和加密学。",
    
    "about.name-5-1":"高级软件工程师",
    "about.txt5-1":"在多年的自由职业网络开发人员工作后，对加密货币产生浓厚兴趣，两年前开始开发dApp。",

    "about.name-6-1":"软件工程师",
    "about.txt6-1":"最近的毕业生，在大学期间对区块链技术的无信任架构产生浓厚兴趣，大部分时间用于研究区块链，目前是dApp开发团队的一员。",

    "about.name-7-1":"软件工程师",
    "about.txt7-1":"在Solidity开发方面拥有超过2年的经验。Syhami曾是BcVote项目的核心成员，该项目参加了CPAC 2020。Syhami是后端技术专家。",

    "see.more":"查看更多",
    "current.location":"当前位置",
    "Partner.Details":"合作伙伴详情",

    "about.maxTitle1":"董事会成员介绍",
    "about.maxTitle2":"团队成员介绍",

    "Partner.title1-1":"方舟电动车 — 阿联酋的环保之选",
    "Partner.txt1-2":"概要：",
    "Partner.txt1-3":"方舟电动车是阿联酋阿布扎比推出的全新电动汽车品牌，以其环保性能和豪华特点引人瞩目。作为阿联酋的首家电动汽车制造商，方舟致力于在未来的城市中为乘客带来环保、高品质的出行体验。",
    "Partner.txt1-4":"特点：",
    "Partner.txt1-5":"贵宾 - 出租车： 方舟电动车被誉为“绿色”出租车，采用全电动驱动，无排放、无雾霾，对环境友好。",
    "Partner.txt1-6":"周边友好： 适应21世纪城市需求，低噪音、无污染，为城市居民提供宁静的出行环境。",
    "Partner.txt1-7":"极致舒适： 设有电动门，扩展安全性，VIP内饰，所有座位都可欣赏全景。",
    "Partner.txt1-8":"内饰可变性： 可转换为出租车、商务舱或贵宾座椅，满足不同出行需求。",
    "Partner.txt1-9":"创新设计： 采用100%碳复合材料，100%电力驱动，拥有弧形全景挡风玻璃和高级开放式空间。",
    "Partner.txt1-10":"规格：",
    "Partner.txt1-11":"类别： 法拉克（ARK）",
    "Partner.txt1-12":"尺寸： 长 x 宽 x 高为4.65m x 1.90m x ?",
    "Partner.txt1-13":"空重： 1600公斤",
    "Partner.txt1-14":"有效载荷： 500千克",
    "Partner.txt1-15":"续航里程： 最多400公里",
    "Partner.txt1-16":"最高时速： 160公里/小时（有限）",
    "Partner.txt1-17":"排放： 真正的零排放",
    "Partner.txt1-18":"电池容量： 63千瓦时",
    "Partner.txt1-19":"创始人：",
    "Partner.txt1-20":"谢赫·穆罕默德·本·艾哈迈德·本·哈姆丹·阿勒纳哈扬殿下： 阿联酋的领导者，方舟电动车创始人，总统的侄子。他的愿景是将电动汽车带入阿联酋的道路，为城市提供更清洁和可持续的交通选择。",
    "Partner.txt1-21":"历程：",
    "Partner.txt1-22":"2022年11月1-2日： 方舟电动车在阿联酋举行盛大发布会，展示了全新电动车型，吸引了媒体和政府官员等各界人士的关注。",
    "Partner.txt1-23":"联系方式：",
    "Partner.txt1-24":"邮箱： info@arkmotors.tech",
    "Partner.txt1-25":"地址： 阿联酋迪拜千禧广场酒店15楼",
    "Partner.txt1-26":"方舟电动车是中东地区的第一家电动汽车制造商，将带来环保出行新体验。敬请期待！ （版权所有 © ARK MOTORS & TECHNOLOGY L.L.C.）",


    "Partner.title2-1":"DDE",
    "Partner.txt2-1":"DDE目的：",
     "Partner.txt2-2":"用技术的力量连接人与企业，扩大社会的可能性。",
     "Partner.txt2-3":"随着时代的变化和前所未有的事件，技术的进步，社会和产业的情况即将发生巨大的变化。现在，企业的价值不能仅仅通过业绩来衡量。",
     "Partner.txt2-4":"我认为，像以前一样只有本公司获胜，只追求经济价值的话，就绝对不能成为持续且被爱的存在。",
     "Partner.txt2-5":"对客户的价值，对合作伙伴的价值，对社会和环境的价值。要把这些都全面地提高。不是满足于短期的数字，而是培养人们，建立事业，创造可持续的未来。",
     "Partner.txt2-6":"那就是我们DDE的承诺。",
     "Partner.txt2-7":"DDE顶级信息:",
     "Partner.txt2-8":"我们DDE的愿景是在信息中找到价值，并将其视为商业创新的源泉，通过对社会的贡献，创造新的未来。经常灵活地采用最新技术和想法，以先进的方法开展业务，积极推进面向下一代的创新。",
     "Partner.txt2-9":"我坚信我们的活动可以对整个社会的未来产生积极的影响。正因为是变化激烈的现代社会，所以毫不畏惧地挑战，不断追求新的可能性，实现行政和大企业无法做到的事情。还有，我认为实现迄今为止谁都做不到的有影响力的社会贡献是我们的使命。",
     "Partner.txt2-10":"挑战是刻在我们的DNA里。我想成为通过各种活动不断展示新的社会贡献方式的领先公司(变革者)。",
     "Partner.txt2-11":"不满足于短期的成功，为了不断成长不断改进，为了实现更好的未来而不断进化。从长期的角度展望事业，思考如何创造可持续的未来。",
     "Partner.txt2-12":"我们DDE今后也会继续进化为创新和社会贡献，建设可持续未来的技术领域的专业团体。",
     "Partner.txt2-13":"DDE公司简介",
     "Partner.txt2-14":"株式会社DDE FINTECH HOLDING",
     "Partner.txt2-15":"英文名称",
     "Partner.txt2-16":"DDE FINTECH HOLDING LTD",
     "Partner.txt2-17":"设立",
     "Partner.txt2-18":"2003年10月1日",
     "Partner.txt2-19":"代表人",
     "Partner.txt2-20":"代表董事 奥岛 健司",
     "Partner.txt2-21":"资本金",
     "Partner.txt2-22":"5,000万日元(截至2019年12月末)",
     "Partner.txt2-23":"员工人数",
     "Partner.txt2-24":"50名(截至2020年4月末)",
     "Partner.txt2-25":"本社所在地",
     "Partner.txt2-26":"〒135-0091 东京都港区台场2-2-4",
     "Partner.txt2-27":"公司名称",

     "Partner.title3-1":"Libes - 打造eSports新经济圈",
     "Partner.txt3-1":"简介：",
     "Partner.txt3-2":"Libes是一个旨在为电竞市场创造新的经济生态圈，提升电竞的经济性和价值的平台。除了传统的电竞经济模式，我们还将P2P要素和区块链融入平台，扩展经济活动选择，为电竞选手提供经济支持，并为用户带来全新的乐趣。通过报名、赠送和NFT等结合，这一目标得以实现。",
     "Partner.txt3-3":"特点：",
     "Partner.txt3-4":"增强经济性： 打造新的经济圈，提升电竞自身的经济性和价值。",
     "Partner.txt3-5":"P2P和区块链： 引入P2P和区块链技术，拓展经济活动领域。",
     "Partner.txt3-13":"支持电竞普及： 提供多方面支持，包括赞助职业队伍和改善电竞环境。",
     "Partner.txt3-6":"使用指南：",
     "Partner.txt3-7":"Libes平台不仅提供服务，还积极参与电竞普及和支持。我们的目标是为整个电竞市场的发展做出贡献。",
     "Partner.txt3-8":"联系方式：",
     "Partner.txt3-9":"隐私政策：了解有关我们的隐私政策的详细信息。",
     "Partner.txt3-10":"利用条款：详细了解Libes平台的使用条款。",
     "Partner.txt3-11":"通过Libes，我们努力为电竞市场带来更多可能性和活力，为电竞爱好者和选手创造更美好的未来。",
   
     "Partner.title4-1":"九九华联株式会社",
     "Partner.txt4-1":"九九华联株式会社简介：",
     "Partner.txt4-2":"九九华联株式会社（99hualian Inc.）是一家重视人际联系的企业。总部位于日本长野县松本市，东京本部位于台场。成立于2020年7月，注册资本为1000万日元，员工人数为20名（包括集团子公司）。该公司在日本和中国实现了线下实体店与数字空间的融合。",
     "Partner.txt4-3":"业务内容：",
     "Partner.txt4-4":"九九华联致力于在日本和中国实现线下实体店和数字空间的融合，目标是在日本开设1000家店铺。此外，他们还利用Web3.0和区块链技术构建了与数字空间互动的实体店。这有助于成为地区商品提供的窗口，促进日本和中国商品的交流。",
     "Partner.txt4-5":"九九华联还注重先进技术的引入和舒适的空间营造，致力于建立超越跨境电子商务的新型商品流通网络。此外，他们还在开发各店铺可使用的支付应用程序，为客户提供融合了实体与数字的服务。",
     "Partner.txt4-6":"该公司也重视环境保护，通过引入电动汽车和V2H（车辆到家庭）等措施，积极推进脱碳和碳中和的事业。此外，他们还利用中国制造的下一代农业机械，推动智能农业，实现节能和高效，为解决当前所面临的问题做出贡献。",
     "Partner.txt4-7":"最新公告：",
     "Partner.txt4-8":"九九华联提供了每日活动和新店开业等信息的通知页面。最新消息显示，2023年8月，『九九华联 札幌东』开业。此前，该店的开业通告已经发布，过去半年的店铺开设和各地区的新计划也得到了详细介绍。",
     "Partner.txt4-9":"总结：",
     "Partner.txt4-10":"九九华联株式会社致力于在日本和中国推动实体与数字融合，展开店铺开设、环保、技术应用等多样业务。该公司通过促进两国商品交流和提升客户服务，创造了新的商业模式。",
    
     "Partner.title5-1":"WFC俱乐部：连接、传播、保护，共创未来笑容",
     "Partner.txt5-1":"简介：",
     "Partner.txt5-2":"WFC俱乐部是一个以促进新技术和微笑为目标的区域振兴项目。通过连接、传播和保护，我们致力于将社区与新技术融合，让日本与世界各地的人们共同享受生活的乐趣。",
     "Partner.txt5-3":"核心价值：",
     "Partner.txt5-4":"传播： 我们以社区为基础，通过传播新技术、创意和信息，将人们紧密联系在一起。",
     "Partner.txt5-5":"连接： 无论是本地还是外国，无论是传统还是未来，我们通过“团结”来增加人们的活力和笑容。",
     "Partner.txt5-6":"保护： 我们传承着传统技能、文化和农业等，将它们传递给下一代，保护社区的独特价值。",
     "Partner.txt5-7":"活动与服务：",
     "Partner.txt5-8":"会员支持活动",
     "Partner.txt5-9":"社会贡献活动",
     "Partner.txt5-10":"活动遍布日本各地，包括北海道、东日本、关东、北日本、中部、关西、西日本、四国和沖绳。",
     "Partner.txt5-11":"会员资格：",
     "Partner.txt5-12":"欢迎各界人士加入我们的俱乐部，一同传播新技术、连接人与人、保护传统。",
     "Partner.txt5-13":"联系方式：",
     "Partner.txt5-14":"地址：东京都港区台场2-2-4 ザ・タワーズ台場 イースト 台場クリニックモール ３階",
     "Partner.txt5-15":"电车：从“お台場海浜公园”站下车，步行约4分钟即可到达。",
     "Partner.txt5-16":"通过WFC俱乐部，我们致力于为社区注入更多生机和活力，让人们拥有灿烂笑容，共创美好未来。",
    
     "Partner.title6-1":"楽座（Rakuza）—通过NFT保护日本动漫文化",
     "Partner.txt6-1":"楽座（Rakuza）简介：",
     "Partner.txt6-2":"楽座（Rakuza）是一个致力于通过非同质化代币（NFT）来保护和增强日本动漫文化重要性的平台。动漫作为日本引以为傲且备受全球认可的文化资产。该平台介绍了传统的日本浮世绘木刻版画（Ukiyo-e）世界，以及动画细胞（Cels）的历史重要性。",
     "Partner.txt6-3":"浮世绘与动画细胞：文化的相似之处",
     "Partner.txt6-4":"浮世绘在江户时代风靡一时，以其多样的主题和广泛的吸引力迷住了大众。尽管在某些时候在国内被低估，但随后它在数字时代获得了珍视，因其描绘了逝去时代的珍贵历史遗迹。同样地，动画细胞作为早期动画制作的重要部分，在使用后通常被丢弃。然而，在数字时代，随着对其艺术价值和稀有性的认可增加，它们的价值不断攀升。",
     "Partner.txt6-5":"楽座的重要性：",
     "Partner.txt6-6":"楽座解决了保护动画细胞遗产的需求。这些物理艺术品容易受到破坏，而数字数据缺乏内在价值和安全性。通过区块链技术，楽座旨在赋予数字资产真实性和所有权验证，使NFT成为一个理想的解决方案。通过NFT化物理动画细胞并保护它们，楽座旨在确保它们在全球艺术市场中的地位，并确保日本文化遗产的延续。 该平台的使命与浮世绘的历程相吻合，后者从曾经被低估到成为珍视的文化珍宝。楽座努力成为日本流行文化及其创作者的守护者，培育一个蓬勃发展的经济生态系统。致力于保护和培养动漫文化，楽座旨在为日本动漫遗产的保护和发展做出贡献，同时将NFT视为实现这一目标的手段。",
     "Partner.txt6-7":"欲了解更多信息，探索动漫文化保护的世界，或查询平台的服务，请访问楽座的官方网站。",

    "footer.txt-1":"地址",
    "footer.txt-2":"电话",
    "footer.txt-3":"邮箱",
    "footer.txt-5":"〒135-0091 東京都港区台場2-2-4"

    }