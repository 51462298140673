import { createStore } from 'vuex'

export default createStore({
  state: {
    langSelect:'ZH'
   
  },
  getters: {
  },
  mutations: {
    // 切换语言
    updateLangSelect (state, payload) {
      state.langSelect = payload
      console.log(payload,'payload')
    },
  },
  actions: {
  },
  modules: {
  }
})
